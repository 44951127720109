import Vue from 'vue'

export default {
    fetchMessages: function (filters) {
        return Vue.$http.get('messages', {
            params: filters
        });
    },

    fetchMessage: function (id) {
        return Vue.$http.get(`messages/${id}`);
    },

    storeMessage: function (data) {
        return Vue.$http.post('messages', data);
    },

    updateMessage: function (id, data) {
        return Vue.$http.put(`messages/${id}`, data);
    },

    deleteMessage: function (id) {
        return Vue.$http.delete(`messages/${id}`);
    },
}
