<template>
  <v-dialog v-model="showDialog" :fullscreen="$vuetify.breakpoint.mobile" width="700">
    <v-sheet id="message-create-popup" class="pa-6 pa-md-10" color="white">
      <div class="d-flex gap-10">
        <div>
          <h2 class="mb-5">Message</h2>
        </div>
        <div class="ml-auto">
          <v-btn @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </div>



      <div>
        <v-select v-model="$v.form.category.$model"
          :items="categories"
          label="Message Category"
          placeholder="Message Category"
          outlined
        ></v-select></div>

      <div v-if="form.category === 2">
        <v-select
                  v-model="form.type"
                  :items="messageTypes"
                  label="Message Type"
                  placeholder="Message Type"
                  outlined
        ></v-select>

      </div>



      <div class="d-flex gap-10 mb-7" >
        <div>
          <select-customer-dropdown v-model="form.customer_id" :prefilledValue="$route.name === 'customers.messages' ? $route.params.id : null"/>
        </div>
        <div v-if="form.category === 1 || form.category === 3">
          <select-caretaker-dropdown v-model="form.caretaker_id" :prefilledValue="$route.name === 'users.caretaker-messages' ? $route.params.id : null"/>
        </div>
        <div v-if="form.category === 3">

          <v-text-field outlined :label="'Other  Person'" v-model="form.other"/>
          <v-input label="Other Person"></v-input>
<!--          <select-assignee-dropdown v-model="form.assigned_id" label="Other person"/>-->
          <div class="text-caption red--text" v-if="$v.form.assigned_id.$dirty && $v.form.assigned_id.$invalid && !$v.form.assigned_id.required">Field is required</div>
        </div>
      </div>

      <div>
        <Files @files-upload="handleFilesUpload" ref="files"/>
      </div>

     <div class="mt-7">
      <v-textarea v-model="form.text" outlined placeholder="Message Text" label="Message Text" />
     </div>


      <div class="d-flex">
        <v-btn class="mt-5 mr-auto" color="primary" @click="submit">SUBMIT</v-btn>
      </div>
    </v-sheet>
  </v-dialog>
</template>

<script>
import SelectCustomerDropdown from "../../../../components/forms/SelectCustomerDropdown";
import {EventBus} from "../../../../bootstrap/EventBus";
import SelectCaretakerDropdown from "../../../../components/forms/SelectCaretakerDropdown";
import Files from "../../../../components/forms/Files";
import messagesApi from "../../../../api/modules/messages"
import {required, requiredIf} from 'vuelidate/lib/validators'
import SelectAssigneeDropdown from "@/components/forms/SelectAssigneeDropdown";

export default {
  name: "MessageCreatePopup",
  components: {SelectCaretakerDropdown, SelectCustomerDropdown, Files, SelectAssigneeDropdown},
  data: function () {
    return {
      showDialog: false,
      categories: [
        {
          text: 'Simple Message',
          value: 1
        },
        {
          text: 'Classified Message',
          value: 2
        },
        {
          text: 'Logbuch',
          value: 3
        }
      ],
      messageTypes: [
        {
          text: 'Contract Termination',
          value: 1
        },
        {
          text: 'Client’s Death',
          value: 2
        },
      ],
      form: {
        category: null,
        type: null,
        customer_id: null,
        caretaker_id: null,
        text: null,
        task_id: null,
        files: null,
        assigned_id: null
      }
    }
  },
  validations: {
    form: {
      category: {
        required
      },
      customer_id: {
        required
      },
      type: {
        required: requiredIf(function (item) {
          return item.category === 2
        })
      },
      caretaker_id: {
        required: requiredIf(function (item) {
          return item.category === 1 || item.category === 3
        })
      },
      assigned_id:{
        required: requiredIf(function (item) {
          return item.category === 3
        })
      },
      text: {
        required
      }
    }
  },
  created: function () {
    EventBus.$on('message-create-popup', () => {
      this.showDialog = true;
    });
  },
  mounted() {
    if (this.$route.name === 'users.caretaker-messages') {
      this.form.caretaker_id = this.$route.params.id
    }
    if (this.$route.name === 'customers.messages') {
      this.form.customer_id = this.$route.params.id
    }
  },
  methods: {
    handleFilesUpload(formData) {
        this.form.files = formData
    },
    submit: function () {
      this.$v.$touch()
      if(this.$v.$invalid) return;
      const formData = new FormData();
      if (this.form.files) {
        for (const pair of this.form.files.entries()) {
          formData.append('files[]', pair[1]);
        }
      }
      if (this.form.type) formData.append('type', this.form.type)
      if (this.form.category === 1 || this.form.category === 3) formData.append('caretaker_id', this.form.caretaker_id)
      if (this.form.category === 3) formData.append('assigned_id', this.form.assigned_id)
      formData.append('text', this.form.text)
      formData.append('category', this.form.category)
      formData.append('customer_id', this.form.customer_id)
      messagesApi.storeMessage(formData).then(() => {
        this.closeModal()
      })
    },
    closeModal() {
      this.form = Object.assign({}, {
        category: null,
        type: null,
        customer_id: null,
        caretaker_blank_id: null,
        text: null,
        task_id: null,
        files: null
      })
      this.$refs.files.toggleUploader()
      this.$v.$reset()
      this.showDialog = false
      EventBus.$emit('refetch-messages');
    }
  }
}
</script>
